<template>
    <div>
        <ValidationObserver ref="filterForm">
            <b-row>
                <b-col cols="12" md="6">
                    <Validation-provider name="faculty_code" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="filter.faculty_code"
                                :validate-error="errors[0]"></faculty-selectbox>
                        </b-form-group>
                    </Validation-provider>
                </b-col>

                <b-col cols="12" md="6">
                    <Validation-provider name="program_code" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('program')">
                            <ProgramSelectbox v-model="filter.program_code" :faculty_code="filter.faculty_code"
                                :validate-error="errors[0]" />
                        </b-form-group>
                    </Validation-provider>
                </b-col>

                <b-col cols="12">
                    <b-button variant="primary" @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox.vue";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox"

import LecturerReportService from "@/services/LecturerReportService"
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import qs from 'qs'

export default {
    components: {
        FacultySelectbox,
        ProgramSelectbox,
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            filter: {},
            responseType: 'excel',
        }
    },
    methods: {
        async getReport() {
            const isValid = await this.$refs.filterForm.validate()
            if (isValid) {
                const config = {
                    params: {
                        filter: {
                            ...this.filter
                        },
                        response_type: 'excel'
                    },
                    paramsSerializer: (params) => qs.stringify(params, { encode: false })
                }

                LecturerReportService.excel(config)
                    .then((response) => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                    })
                    .catch((error) => {
                        this.showErrors(error)
                    })
            }
        }
    }
}
</script>
