<template>
    <div>
        <b-row>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('semester')">
                    <semesters-selectbox v-model="filter.semester_id" ></semesters-selectbox>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('faculty')">
                    <faculty-selectbox v-model="filter.faculty_code" ></faculty-selectbox>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('report_type')">
                    <multi-selectbox 
                        :allow-empty="false" :multiple="false"
                        :options="responseTypeOptions" v-model="responseType"
                    ></multi-selectbox>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-button variant="primary" @click="getReport">
                    {{ $t('get_report').toUpper() }}
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox"
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox"
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"
import LecturerReportService from "@/services/LecturerReportService"
import qs from "qs"

export default {
    components: {
        SemestersSelectbox,
        FacultySelectbox,
        MultiSelectbox,
    },
    data() {
        return {
            filter: {},
            responseType: 'page',
            responseTypeOptions: [
                {value: 'page', text: this.$t('page')},
                {value: 'excel', text: this.$t('excel')},
                {value: 'pdf', text: 'PDF'}
            ]
        }
    },
    methods: {
        async getReport() {
            const config = {
                params: {
                    filter: {
                        ...this.filter
                    },
                    response_type: this.responseType
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }

            LecturerReportService.getGradeEntryReportByFaculty(config)
                .then((response) => {
                    this.$toast.success(this.$t('api.' + response.data.message))
                    this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                })
                .catch((error) => {
                    this.showErrors(error)
                })    
        }
    }
}
</script>
